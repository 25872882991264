/* Global Classes */



.title-text {
  font: normal 50px/72px;
  font-weight: bold;
}


.text-uppercase {
  text-transform: uppercase;
}


/* Buttons */


.button {
  padding: 0.8rem 2.2rem;
  font: normal 16px/20px;
  font-weight: 500;
  position: relative;
  border: 3px solid transparent;
  border-radius: 4px;
}

.button.primary-button {
  background: radial-gradient(farthest-corner circle at 75% 70%, #3356b2, #7479e5);
  /* background: var(--gradient-color); */
  background-clip: padding-box;
  color: whitesmoke;
  transition: background 0.6s ease;
  
  box-shadow: var(--box-shadow);
}



.button.primary-button:hover {
  background: whitesmoke;
  background-clip: padding-box;
  color: black;
}

.button.primary-button::after, .button.secondary-button::after {
  position: absolute;
  top: -3px;
  left: -3px;
  bottom: -3px;
  right: -3px;
  background: var(--gradient-color);
  content: '';
  z-index: -1;
  border-radius: 4px;
}



.button.secondary-button {
  background: white;
  background-clip: padding-box;
  transition: background .6s ease;
}

.button.secondary-button:hover {
  background: var(--gradient-color);
  color: whitesmoke;
  box-shadow: var(--box-shadow);
}


.button.project-button {
  background: var(--primary-color2);
  background-clip: padding-box;
  color: black;
  transition: background 0.6s ease;
  box-shadow: var(--box-shadow);
}

.button.project-button:hover {
  background: whitesmoke;
  background-clip: padding-box;
  color: black;
}

.button.project-button::after {
  position: absolute;
  background: var(--gradient-color);
  content: '';
  z-index: -1;
  border-radius: 4px;
}



p.para {
  color: var(--text-color);
  font: normal 20px/30px;
  font-weight: 500;
}


.font-roboto{
  font-family: var(--roboto);
}

button:focus,
button:active
button:hover
.btn:active,
.btn.active{
  outline: 0px !important;
  box-shadow: none !important;
}


.title-h1{
  font: normal 39px/ 40px;
  font-weight: bold;
}


.navbar_fixed{
  /* position: fixed; */
  top: -1px;
  left: 0; right: 0;
  max-height: 80px;

  /* box-shadow: var(--box-shadow); */
  z-index: 999;
  transition: background 1s ease;
}

.text-secondary{
  color: var(--text-color);
}


.bg-nav{
  background-color: transparent;
}


.bg-collapse{
  background-color: transparent;
}