/* If the viewport is less than eq 440px */
@media only screen and (max-width: 440px){
  .header_area .main-menu .navbar,
  .header_area .main-menu .navbar .navbar-brand
  {
    padding: 2%;
    margin: 0%;
  }

  .site-main .about-area,
  .site-main .site-banner
   {
    padding-top: 0;
  }

  .site-main .site-banner .banner-image{
    padding: 2rem 0 0;
    width: 90%;
    height: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .site-main .about-area .about-image{
    padding: 4rem 0 0;
    width: 90%;
    height: 90%;
    margin-left: auto;
    margin-right: auto;
  }



  .site-main .site-banner .site-title {
    padding-top: 25%;
    padding-bottom: 17%;
  }
  .site-main .site-banner .site-title h1{
    font-size: 41px;
  }

  .site-main .about-area .about-title {
    padding-top: 18%;
  }


 


  .site-main .services-area .skills-list{
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .site-main .services-area .skills-list .bar-layer{
    padding-left: 0;
    padding-right: 0;
  }



  .site-main .project-area .element-item .our-project .img
  /* .site-main .project-area .element-item .our-project .im */
  {
    
    width: 100%;

  }

  .site-main .project-area .element-item .our-project .img .img-description-layer{
    font-size: 12px;
  }

  .site-main .project-area .element-item .our-project .img .img-description-layer .img-button a{
    font-size: 14px;
    width: 40%;
    padding: 0.5rem 1rem;
  }

  .footer-area .social a > i{
    padding-left: 1rem;
    padding-right: 1rem;
  }


}

@media only screen and (max-width: 792px){

  .img-fluid{
    padding: 0 !important;
    margin: 0;
  }


  .site-main .site-banner .site-title h3{
    padding: 0;
  }

  .site-main .site-banner .site-title {
    padding-top: 15%;
  }

  .site-main .site-banner .banner-image{
    padding: 3rem 0 1rem;
    text-align: center;
  }

  .site-main .about-area .about-image{
    padding: 4rem 0 0;
    text-align: center;

  }

  
  .site-main .about-area .about-title h2 > span{
    
    font-size: 31px;
    letter-spacing: 10px;
  }


  
  .site-main .about-area .about-title p{
    padding: 0%;
  }

  
  .site-main .project-area .element-item .our-project .img
  /* .site-main .project-area .element-item .our-project .im */
  {
    max-width: 360px;
    width: 100%;

  }


}


@media only screen and (max-width: 1910px){

  
  .header_area .main-menu .navbar{
    padding: 1rem;
  }

  .container{
    max-width: 90%;
  }

  .site-main .services-area .services-title{
  /* .site-main .about-area{ */
    padding: 3% 4%;
  }

  .bg-nav, .bg-collapse{
    background-color: #8abadf;
  }
}