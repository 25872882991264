/*import fonts from fonts.css file*/
/* @import url(./css/fonts.css); */
/* css variables */
@import url(./css/partials/_variables.css);

@import url(./css/partials/_global.css);


.site{
  background: linear-gradient(#89badf, #d797dd, #efcfaf);
}

.slideLeft {
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
  opacity: 0;
  transform: translateX(-100%);
}
.slideLeft.enter {
  transition: opacity 1.5s ease-in-out, transform 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
  transform: translateX(0);
}
.slideUp {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  opacity: 0;
  transform: translateY(100%);
}
.slideUp.enter {
  transition: opacity 1s ease-in-out, transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
  transform: translateY(0);
}

/* -----------------navigation start--------------- */


.header_area .main-menu .navbar .navbar-brand {
  padding: 0 2rem 0 5rem;
}

.header_area .main-menu .navbar {
  padding: 1rem 20rem;
}

.header_area .main-menu .navbar a > img {
  padding-top: 1rem;
  width: 60px;
}

.header_area .main-menu .nav-item .nav-link {
  font: normal 15px/2px; /* font property*/
  font-weight: 500;
  text-transform: uppercase;
  padding: 1.7rem;
  color: var(--title-color);
}

/* Home button has gradient color*/
.header_area .main-menu .navbar-nav .active a {
  background: var(--primary-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* When hovering over nav links, show gradient color*/
.header_area .main-menu .navbar-nav a:hover {
  background: var(--primary-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* -----------------navigation end--------------- */


/* -----------------banner start--------------- */

.site-main .site-banner {
  /* CHANGE BACKGROUND LATER */
  /* background: url(./img/banner.png) no-repeat 0% 50%; */
  padding: 4rem 0 2rem;
  /* background-color: #89badf; */
}

.site-main .site-banner .site-title h3 {
  padding-top: 30%;
  font-weight: 500;
  margin: 0 auto;
  
}

/* .site-main .site-banner .site-title h3::after {
  content: "";
  height: 2px;
  width: 18vw;
  background: black;
  display: inline-block;
  margin-left: 5%;
  transform: translateY(-10px);
} */

.site-main .site-banner .site-title h1 {
  font-size: 5rem;
}

.site-main .site-banner .site-title h4 {
  font-size: 1.5rem;
  padding-bottom: 1rem;

}

.site-main .site-banner .banner-image > img {
  width: 450px;
  padding: 7rem 0;
}

/* -----------------banner end--------------- */


/* -----------------about start--------------- */

.site-main .about-area {
  padding: 9rem 0 8rem;
  /* background-color: #d1e3bc; */
}

.site-main .about-area .about-image > img {
  width: 500px;

  padding: 3rem 0rem;
}

.site-main .about-area .about-title h2 > span {
  display: block;
  font: normal 49px/60px;
  font-weight: bold;
  letter-spacing: 10px;
}

.site-main .about-area .about-title .paragraph > p {
  padding: .4rem 0;
}


/* -----------------about end--------------- */



/* -----------------services start--------------- */

.site-main .services-area{
  padding: 9rem 0 8rem;
  /* background-color: #efcfaf; */
}

.site-main .services-area .skills-list{
  padding-top: 2rem;
  padding-bottom: 5rem;
  width: 80%;
  margin: 0 auto;
}



.site-main .services-area .skills-list .bar-layer{
  padding: 0.5rem;
}

.site-main .services-area .skills-list .bar-layer .bar-to-fill{
  background: whitesmoke;
  display: block;
  height: 35px;
  overflow: hidden;
}



.site-main .services-area .skills-list .bar-layer .bar-filled{
  height: 35px;
  float: left;
  background: lightskyblue;
}

.site-main .services-area .skills-list .bar-layer .bar-to-fill .bar-filled .bar-tag{
  width: 105px;
  height: 35px;
  float: left;
  background: var(--primary-color);
}


.site-main .services-area .skills-list .bar-layer .bar-to-fill .bar-filled .bar-tag > h6{
  margin: 0 0 0 0;
  text-align: center;
  padding-top: 6px;
  font-weight: 500;
}

.site-main .services-area .skills-list .bar-layer .bar-to-fill .bar-percent{
  height: 35px;
  text-align: center;
  float: right;
  padding-right: 8px;
  padding-top: 4px;
}

.site-main .services-area .skills-list .bar-layer .bar-to-fill .bar-percent > h5{
  font-weight: 300;
}



.site-main .services-area .services-title{
  padding: 0 14rem;
}

.site-main .services-area .services-title h1{
  font-size: 35px;
}

.site-main > .services-area .services{
  background: rgba(242, 247, 248, 0.973);
  padding: 2rem 0;
  width: 16rem;
  margin: 5rem .5rem;
  transition: box-shadow .7s ease;
}

.site-main > .services-area .services:hover{
  box-shadow: var(--box-shadow);
}

/* -----------------services end--------------- */



/* -----------------project start--------------- */

.site-main .project-area{
  padding: 8rem 0;
  /* background-color: #d797dd; */

}

.site-main .project-area .button-group button{
  background: transparent;
  border: none;
  font: normal 16px/130px;
  font-weight: 500;
  text-transform: uppercase;
}


/*.site-main .project-area .button-group button + button{
  padding-left: 3rem;
  padding-bottom: 2rem;
}*/

.element-item {
  height: fit-content;
}

.site-main .project-area .grid .our-project .img{
  position: relative;
  height: fit-content;
  width: 360px;

}



.site-main .project-area .grid .our-project .img .img-description-layer{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2c3e50;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  transition: opacity .2s, visibility .2s;
}

.site-main .project-area .grid .our-project .img .img-description-layer .img-description{
  padding: 1rem;
  transition: .2s;
  transform: translateY(1em);
}

.site-main .project-area .grid .our-project .img .img-description-layer .img-button{
  text-align: center;
  
}


.site-main .project-area .grid .our-project > .title h4{
  font: normal 25px/12px;
  font-weight: 700;
}

/* ...img a:hover img*/
.site-main .project-area .grid .our-project .img:hover .img-description-layer{
  /* filter: brightness(1.1) drop-shadow(1px 8px 30px #b1afaf); */
  
  box-shadow: 1px 8px 30px #b1afaf;
  visibility: visible;
  opacity: 1;
}

.site-main .project-area .grid .our-project .img:hover .img-description{
  transform: translateY(0);
}

.site-main .project-area .grid .our-project > .title span{
  color: var(--text-color);
  font: normal 20px/30px;
  font-weight: 500;
}

/* -----------------project end--------------- */


/* -----------------footer start--------------- */

.footer-area{
  padding: 5rem 0;
}

.footer-area .social h5{
  font: normal 23px/30px;
  font-weight: 500;
}

.footer-area .social a > i{
  font-size: 30px;
  padding: 3rem;
  color: var(--border-color);
}


.footer-area .social a:hover > i{
  color: var(--primary-color);
}
/* -----------------footer end--------------- */


